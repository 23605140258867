import { client } from '../../../../types/client'
import { permission } from '../../../../types/permission'
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo'
import ContactsAndRoles from './ContactsAndRoles'
import EditButton from './EditButton'
import InfoTable from './InfoTable'

export default function GeneralTab(props: {
  clientDoc: client
  permissions: permission
  isMyClient: boolean
  editMode: boolean
  setEditMode: Function
}) {
  const client = props.clientDoc
  return (
    <>
      <AdditionalInfo client_id={client.id} />
      <hr className=" border-gray-400 py-4"></hr>
      <ContactsAndRoles
        clientDoc={props.clientDoc}
        permissions={props.permissions}
        editMode={props.editMode}
        isMyClient={props.isMyClient}
      />
      <hr className=" border-gray-400 py-4"></hr>
      <EditButton
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        permissions={props.permissions}
        isMyClient={props.isMyClient}
      />
    </>
  )
}

/*
Temp Set Aside

<InfoTable clientDoc={props.clientDoc} />
*/
