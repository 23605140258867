import {
  Avatar,
  Badge,
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'
import { changeNotification } from '../types/changeNotification'
import { useContext, useEffect, useState } from 'react'
import { account } from '../types/account'
import { client } from '../types/client'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
} from '../contexts'
import { Navigate } from 'react-router'
import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon,
  BellIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Loading from './Loading'
import { db } from '../firebase'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import findNameFromAccountID from '../functions/findNameFromAccountID'
import swapValueForNameIfAccountID from '../functions/swapValueForNameIfAccountID'
import swapValueForNameIfClientID from '../functions/swapValueForNameIfClientID'

export default function NotificationsDropDown(props: {
  notifications: changeNotification[]
}) {
  const notifications: changeNotification[] = props.notifications.sort(
    (a, b) => {
      return b.exact_time_of_change.valueOf() - a.exact_time_of_change.valueOf()
    }
  )
  const accounts = useContext(AccountsContext)
  const clients = useContext(ClientsContext)
  const currentUser = useContext(CurrentUserContext)
  const [accountDocsByID, setAccountDocsByID] = useState({})
  const [clientDocsByID, setClientDocsByID] = useState({})

  function collectDocsByID(
    notifications: changeNotification[],
    accounts: account[],
    clients: client[]
  ) {
    var accountsByID = {}
    var clientsByID = {}

    var client_in_notification_set = false
    var account_in_notification_set = false

    var all_account_ids: string[] = []
    var all_client_ids: string[] = []

    notifications.forEach((notification) => {
      if (notification.subject_type === 'account') {
        account_in_notification_set = true
        all_account_ids.push(notification.subject_id)
      } else {
        client_in_notification_set = true
        all_client_ids.push(notification.subject_id)
      }
    })

    if (account_in_notification_set) {
      accounts.forEach((account) => {
        if (account.id && all_account_ids.includes(account.id)) {
          accountsByID[account.id] = account
        }
      })
    }
    if (client_in_notification_set) {
      clients.forEach((client) => {
        if (client.id && all_client_ids.includes(client.id)) {
          clientsByID[client.id] = client
        }
      })
    }
    setAccountDocsByID(accountsByID)
    setClientDocsByID(clientsByID)
  }
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (notifications && firstLoad && accounts && clients) {
      setFirstLoad(false)
      collectDocsByID(notifications, accounts, clients)
    }
  }, [clients, accounts, notifications])
  const [navigate, setNavigate] = useState('')
  const [loading, setLoading] = useState(false)
  async function deleteNotifications() {
    const querySnapshot = await getDocs(
      collection(db, 'accounts/' + currentUser.id + '/notifications')
    )
    const ids_to_delete: string[] = []
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        ids_to_delete.push(doc.id)
      }
    })
    ids_to_delete.forEach(async (id) => {
      await deleteDoc(
        doc(db, 'accounts/' + currentUser.id + '/notifications', id)
      )
    })
  }
  function notificationItem(
    img_src,
    img_variant,
    before,
    after,
    author_name,
    subject_name,
    subject_field,
    date_str,
    key,
    redirect_link
  ) {
    return (
      <MenuItem
        placeholder={'.'}
        className={'p-1 flex flex-row h-fit w-full'}
        key={key}
        onClick={() => setNavigate(redirect_link)}
      >
        <div className="h-full w-fit py-1 px-4">
          <Avatar
            placeholder={'.'}
            variant={img_variant}
            className="w-fit h-fit max-w-24 max-h-24 object-contain shadow-md"
            alt="Picture"
            src={img_src ? img_src : ''}
          />
        </div>
        <div className="h-full w-full flex flex-col justify-center align-middle">
          <div className="w-full h-fit flex flex-row justify-around align-middle">
            <Typography placeholder={'.'} variant="h6" color="blue-gray">
              {before}
            </Typography>
            <div className="p-1">
              <ArrowRightIcon className="h-4 w-4" />
            </div>
            <Typography placeholder={'.'} variant="h6" color="blue-gray">
              {after}
            </Typography>
          </div>
          <div className="w-full h-fit flex flex-row justify-left align-middle">
            <Typography
              placeholder={'.'}
              variant="small"
              color="gray"
              className="font-normal px-2"
            >
              {subject_name}
            </Typography>
            <Typography
              placeholder={'.'}
              variant="small"
              color="gray"
              className="font-normal px-0.5"
            >
              /
            </Typography>
            <Typography
              placeholder={'.'}
              variant="small"
              color="gray"
              className="font-normal px-2"
            >
              {subject_field}
            </Typography>
          </div>
          <div className="w-fit h-fit flex flex-row justify-between align-middle">
            <Typography
              placeholder={'.'}
              variant="small"
              color="gray"
              className="font-normal px-2"
            >
              By {author_name}
            </Typography>
            <Typography
              placeholder={'.'}
              variant="small"
              color="gray"
              className="font-normal px-2"
            >
              {date_str}
            </Typography>
          </div>
        </div>
      </MenuItem>
    )
  }
  return (
    <div className="w-24">
      {!(navigate === '') && <Navigate replace to={navigate} />}
      <Menu placement="bottom-end">
        <MenuHandler className=" overflow-visible w-fit flex flex-row">
          <div className="flex flex-row">
            <IconButton
              variant="text"
              placeholder={'.'}
              className=" overflow-visible"
            >
              {notifications.length > 0 ? (
                <Badge content={props.notifications.length} color="green">
                  <BellIcon className="h-6 w-6" />
                </Badge>
              ) : (
                <BellIcon className="h-6 w-6" />
              )}
            </IconButton>
            <div className="pt-1">
              <Chip value="BETA" variant="ghost" color="blue" />
            </div>
          </div>
        </MenuHandler>
        <MenuList placeholder={'.'} className="max-h-96 h-fit w-fit">
          {loading ? (
            <div className="p-8 flex justify-center w-full">
              <Loading />
            </div>
          ) : (
            <>
              <>
                {notifications.map((notification, index) => {
                  const author_name = findNameFromAccountID(
                    notification.author_id,
                    accounts
                  )
                  const before = swapValueForNameIfAccountID(
                    notification.old_value,
                    accounts
                  ) // client id should never be a changed value
                  const after = swapValueForNameIfAccountID(
                    notification.new_value,
                    accounts
                  )
                  if (notification.subject_type === 'account') {
                    const subject_name = swapValueForNameIfAccountID(
                      notification.subject_id,
                      accounts
                    )
                    const doc: account =
                      accountDocsByID[notification.subject_id]
                    if (doc)
                      return notificationItem(
                        doc.avatar,
                        'circular',
                        before,
                        after,
                        author_name,
                        subject_name,
                        notification.field_name,
                        notification.timestamp,
                        index + '_notification_item',
                        '/view/account#' + notification.subject_id
                      )
                  } else if (notification.subject_type === 'client') {
                    const subject_name = swapValueForNameIfClientID(
                      notification.subject_id,
                      clients
                    )
                    const doc = clientDocsByID[notification.subject_id]
                    if (doc)
                      return notificationItem(
                        doc.logo,
                        'rounded',
                        before,
                        after,
                        author_name,
                        subject_name,
                        notification.field_name,
                        notification.timestamp,
                        index + '_notification_item',
                        '/view/client#' + notification.subject_id
                      )
                  } else {
                    return <></>
                  }
                })}
              </>
              {notifications.length > 0 ? (
                <MenuItem
                  placeholder={'.'}
                  onClick={() => {
                    setLoading(true)
                    deleteNotifications()
                  }}
                >
                  <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                    <XMarkIcon className="h-6 w-6" stroke="black" />
                  </div>
                </MenuItem>
              ) : (
                <>
                  <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                    No New Notifications at this time.
                  </div>
                  <MenuItem
                    placeholder={'.'}
                    onClick={() => {
                      setNavigate('/notification_preferences')
                    }}
                  >
                    <div className="w-full h-fit flex flex-row justify-center align-middle p-2 stroke-black">
                      <AdjustmentsHorizontalIcon
                        className="h-6 w-6"
                        stroke="black"
                      />
                    </div>
                  </MenuItem>
                </>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </div>
  )
}
