import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { db } from '../firebase'
import Loading from './Loading'
import { upcDoc } from '../types/upcDoc'
import {
  Button,
  Chip,
  IconButton,
  Input,
  Switch,
  Typography,
} from '@material-tailwind/react'
import { Navigate } from 'react-router'
import { ClientsContext } from '../contexts'
import { client } from '../types/client'
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline'
import XLSX from 'xlsx'

export default function EditUPCs() {
  const table_cell_classes = ' border border-gray-400 py-0 px-1 text-black'
  const templateSheet = [
    [
      'UPC',
      'Case UPC',
      'In Kroger',
      'In Circana',
      'Kroger Dept',
      'Kroger Comm',
      'Kroger Sub Comm',
      'Kroger Sub Comm Code',
      'Circana Dept',
      'Circana Aisle',
      'Circana Category',
      'Circana Sub Category',
      'Description',
    ],
    [
      '001, 002',
      '0001, 0002',
      'true',
      'false',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Example Entry',
    ],
  ]

  const [upcDoc, setUpcDoc] = useState<upcDoc | undefined>()
  const [redirectToClient, setRedirectToClient] = useState(false)
  const [docPulled, setDocPulled] = useState<boolean | undefined>(false)
  const [client, setClient] = useState<client | undefined>()
  const clients = useContext(ClientsContext)
  const [newDocReady, setNewDocReady] = useState(false)
  const [newUpcDoc, setNewUpcDoc] = useState<upcDoc>({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [submitting, setSubmitting] = useState<undefined | Boolean>()
  const [categoriesToggle, setCategoriesToggle] = useState(true)
  const [onboarding, setOnboarding] = useState(false)

  async function fetchUPCs(client_id) {
    var file_id = ''

    clients.forEach((client) => {
      if (client.id === client_id) {
        file_id = client.file_id
        setClient(client)
      }
    })
    const doc_ref_id = doc(db, 'upcDocs', client_id)
    await getDoc(doc_ref_id).then((doc) => {
      if (doc.exists()) {
        console.log(doc.data().upcs)
        setUpcDoc(doc.data().upcs)
        setDocPulled(true)
      } else {
        if (file_id && file_id !== '') {
          fetchByFileID(file_id)
        } else {
          setOnboarding(true)
        }
      }
    })
  }

  async function fetchByFileID(file_id) {
    const doc_ref_file_id = doc(db, 'upcDocs', file_id)
    await getDoc(doc_ref_file_id).then((doc) => {
      if (doc.exists()) {
        setDocPulled(true)
        setUpcDoc(doc.data().upcs)
      } else {
        setOnboarding(true)
      }
    })
  }

  async function handleSubmit() {
    setSubmitting(true)

    const confirmation = window.confirm(
      'Are you sure you want to submit these UPC changes?'
    )

    if (confirmation && client) {
      await addDoc(collection(db, "upcDoc-changes"), {
        client_id: client.id,
        new: newUpcDoc,
        old: upcDoc ? upcDoc : {previously_empty: true}
      })
      await setDoc(doc(db, 'upcDocs', client.id), { upcs: newUpcDoc })
        .then(() => {
          alert(
            'The new UPCs were saved correctly. Redirecting to client page...'
          )
          setRedirectToClient(true)
        })
        .catch(() => {
          alert('There was an error saving. Redirecting to client page...')
          setRedirectToClient(true)
        })
    }
    setSubmitting(false)
  }
  async function processFile(selectedFile: File) {
    var new_upcDoc: upcDoc = {}
    var rejected_lines: string[][] = []
    var headers: any

    const file = await selectedFile.arrayBuffer()
    const workbook = XLSX.read(file)
    const first_sheet = workbook.Sheets[workbook.SheetNames[0]]
    const results = XLSX.utils.sheet_to_json(first_sheet, { header: 1 })

    const rows = results
    console.log('Rows being processed: ', rows.length)
    for (let i in rows) {
      const row = rows[i]
      if (i === '0') {
        headers = row
        console.log(headers)
      } else {
        try {
          if (Array.isArray(row)) {
            if (row.length >= 10) {
              var upc = String(row[0])
              var case_upcs_string: string = row[1] ? String(row[1]) : ''
              var in_kroger_string = row[2] ? row[2] : ''
              var in_circana_string = row[3] ? row[3] : ''
              var kr_dept = row[4] ? row[4] : ''
              var kr_comm = row[5] ? row[5] : ''
              var kr_sub_comm = row[6] ? row[6] : ''
              var kr_sub_comm_code = row[7] ? row[7] : ''
              var cir_dept = row[8] ? row[8] : ''
              var cir_aisle = row[9] ? row[9] : ''
              var cir_category = row[10] ? row[10] : ''
              var cir_sub_category = row[11] ? row[11] : ''
              var description = row[12] ? row[12] : ''
              var case_UPC: string[] = []
              if (case_upcs_string) {
                case_UPC = case_upcs_string.replace(' ', '').split(',')
              }

              var in_circana = false
              if (
                in_circana_string === 'true' ||
                in_circana_string === 'TRUE'
              ) {
                in_circana = true
              }

              var in_kroger = false
              if (in_kroger_string === 'true' || in_kroger_string === 'TRUE') {
                in_kroger = true
              }

              new_upcDoc[upc] = {
                case_UPC: case_UPC,
                cir_aisle: cir_aisle,
                cir_category: cir_category,
                cir_dept: cir_dept,
                cir_sub_category: cir_sub_category,
                description: description,
                in_circana: Boolean(in_circana),
                in_kroger: Boolean(in_kroger),
                kr_comm: kr_comm,
                kr_dept: kr_dept,
                kr_sub_comm: kr_sub_comm,
                kr_sub_comm_code: kr_sub_comm_code,
              }
            } else {
              rejected_lines.push(row)
              console.log(
                'Row was under the expected number of columns and was rejected.'
              )
            }
          } else {
            console.log('row was not array: ', row)
          }
        } catch (err) {
          console.log('An Error Occured when parsing your csv.', row, err)
          if (Array.isArray(row)) {
            rejected_lines.push(row)
          } else {
            alert(
              'One for the rows is not an array and is being skipped. Check the console for more information.'
            )
            console.log('Non-array row: ', row)
          }
        }
      }
    }
    if (rejected_lines !== undefined) {
      console.log('rejected_lines count: ', rejected_lines.length)
    }
    if (Object.keys(new_upcDoc).length > 0) {
      setNewUpcDoc(new_upcDoc)
      setNewDocReady(true)
    } else {
      alert('No valid lines could be parse. Please try again.')
    }
  }
  const onFileChange = (event) => {
    try {
      processFile(event.target.files[0])
    } catch (err) {
      alert('There was an error parsing your file.')
    }
  }
  function downloadUPCList(upcDoc) {
    var sheet: string[][] = []
    sheet.push(templateSheet[0])
    Object.keys(upcDoc).forEach((upc) => {
      sheet.push([
        upc,
        upcDoc[upc].case_UPC,
        String(upcDoc[upc].in_kroger),
        String(upcDoc[upc].in_circana),
        upcDoc[upc].kr_dept,
        upcDoc[upc].kr_comm,
        upcDoc[upc].kr_sub_comm,
        upcDoc[upc].kr_sub_comm_code,
        upcDoc[upc].cir_dept,
        upcDoc[upc].cir_aisle,
        upcDoc[upc].cir_category,
        upcDoc[upc].cir_sub_category,
        upcDoc[upc].description,
      ])
    })
    if (client) {
      downloadSheetAsXLSX(sheet, 'Orbit UPCs ' + client.name + '.xlsx')
    } else {
      downloadSheetAsXLSX(sheet, 'Orbit UPCs Export.xlsx')
    }
    console.log(sheet)
  }
  function downloadSheetAsXLSX(sheet: string[][], file_name: string) {
    // need to generate sheet from upc

    var ns = XLSX.utils.book_new()
    ns.Props = {
      Title: 'new excell sheet',
      Subject: 'UPC List',
      Author: 'OSMG Orbit',
      CreatedDate: new Date(),
    }
    ns.SheetNames.push('Data')

    var nb_data = sheet
    var nb = XLSX.utils.aoa_to_sheet(nb_data)
    ns.Sheets['Data'] = nb

    var nbOut = XLSX.write(ns, { bookType: 'xlsx', type: 'binary' })

    function saveBook(s) {
      var buf = new ArrayBuffer(s.length)
      var view = new Uint8Array(buf)
      for (var i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff
      }
      return buf
    }

    const blob = new Blob([saveBook(nbOut)], {
      type: 'application/octet-stream',
    })

    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url

    a.download = file_name
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }
  function headers() {
    if (categoriesToggle) {
      return [
        'UPC',
        'Case UPC',
        'In Kroger',
        'In Circana',
        'Dept.',
        'Comm',
        'Sub Comm',
        'Sub Comm Code',
        'Description',
      ]
    } else {
      return [
        'UPC',
        'Case UPC',
        'In Kroger',
        'In Circana',
        'Dept.',
        'Aisle',
        'Category',
        'Sub Category',
        'Description',
      ]
    }
  }
  function infoChip(input: boolean) {
    return (
      <Typography
        placeholder="."
        variant="small"
        color="blue-gray"
        className="font-normal flex justify-center"
      >
        {input ? (
          <Chip variant="ghost" color="green" size="sm" value="Yes" />
        ) : (
          <Chip variant="ghost" color="red" size="sm" value="No" />
        )}
      </Typography>
    )
  }
  function toggleCategories() {
    setCategoriesToggle(!categoriesToggle)
  }
  function sortUPCs(upc_array: string[]) {
    return upc_array.sort((a, b) => {
      return a.localeCompare(b)
    })
  }
  function filterValuesByToggle() {
    if (categoriesToggle) {
      return [
        'kr_dept',
        'kr_comm',
        'kr_sub_comm',
        'kr_sub_comm_code',
        'description',
      ]
    } else {
      return [
        'cir_dept',
        'cir_aisle',
        'cir_category',
        'cir_sub_category',
        'description',
      ]
    }
  }

  useEffect(() => {
    if (firstLoad && clients.length > 0) {
      const id = window.location.href.split('#').pop()
      fetchUPCs(id)
      setFirstLoad(false)
    }
  })
  return (
    <div>
      {client ? (
        <div className="bg-white rounded-lg p-4">
          {redirectToClient && (
            <Navigate to={'/view/client#' + client.id} replace />
          )}
          {submitting === undefined ? (
            <>
              {onboarding && (
                <div className="flex flex-col h-full w-full">
                  <div className="p-4 flex flex-row justify-between">
                    <div>
                      <IconButton
                        placeholder={'.'}
                        variant="text"
                        onClick={() => setRedirectToClient(true)}
                      >
                        <ChevronLeftIcon className="h-8 w-8" />
                      </IconButton>
                    </div>
                    <div className="w-full flex flex-row justify-start py-1 px-4">
                      <Typography placeholder={'.'} variant="h4">
                        Add UPCs
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full flex flex-col p-10">
                    <div>
                      <Typography variant="h6" placeholder={'.'}>
                        Directions
                      </Typography>
                      <div className="p-4">
                        To add new UPCs to {client.name} download the template
                        below and fill it out with the details that are relavent
                        for your product.
                        <br />
                        Then upload the excel sheet, confirm your changes in the
                        preview window, and click 'Submit' to upload the new
                        file and save your changes.
                      </div>
                    </div>
                    <div className="flex flex-row justify-around">
                      <div
                        className="lg w-fit rounded border border-blue-gray-700 p-2 shadow-sm"
                        onClick={() =>
                          downloadSheetAsXLSX(
                            templateSheet,
                            'Orbit UPC Upload Template.xlsx'
                          )
                        }
                      >
                        <div className="flex justify-between">
                          <div className="text-blue-700">Download Template</div>
                          <div className="pl-3">
                            <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-10">
                      <hr className="border-blue-800 border" />
                    </div>
                    <div>
                      <Typography variant="h6" placeholder={'.'}>
                        Upload
                      </Typography>
                      <div className="p-4">
                        Uploads must be an xlsx file with the data on the first
                        sheet.
                        <br />
                        The sheet name does not matter but the titles and order
                        of the rows must match the template.
                      </div>
                    </div>
                    <div className="w-96 p-4">
                      <Input
                        type="file"
                        label="UPC List"
                        crossOrigin={'false'}
                        className=""
                        onChange={onFileChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {docPulled && (
                <div>
                  {upcDoc ? (
                    <div className="flex flex-col h-full w-full">
                      <div className="p-4 flex flex-row justify-between">
                        <div>
                          <IconButton
                            placeholder={'.'}
                            variant="text"
                            onClick={() => setRedirectToClient(true)}
                          >
                            <ChevronLeftIcon className="h-8 w-8" />
                          </IconButton>
                        </div>
                        <div className="w-full flex flex-row justify-start py-1 px-4">
                          <Typography placeholder={'.'} variant="h4">
                            Edit UPCs
                          </Typography>
                        </div>
                      </div>
                      <div className="w-full flex flex-col p-10">
                        <div>
                          <Typography variant="h6" placeholder={'.'}>
                            Directions
                          </Typography>
                          <div className="p-4">
                            Download either the template or the current UPC
                            export, alter it as needed then upload the new
                            values.
                            <br />
                            You can preview the new values before submitting the
                            change below.
                          </div>
                        </div>
                        <div className="flex flex-row justify-around">
                          <div
                            className="lg w-fit rounded border border-blue-gray-700 p-2 shadow-sm"
                            onClick={() =>
                              downloadSheetAsXLSX(
                                templateSheet,
                                'Orbit UPC Upload Template.xlsx'
                              )
                            }
                          >
                            <div className="flex justify-between">
                              <div className="text-blue-700">
                                Download Template
                              </div>
                              <div className="pl-3">
                                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                              </div>
                            </div>
                          </div>
                          <div
                            className="lg w-fit rounded border border-blue-gray-700 p-2 shadow-sm"
                            onClick={() => downloadUPCList(upcDoc)}
                          >
                            <div className="flex justify-between">
                              <div className="text-blue-700">
                                Download Current List
                              </div>
                              <div className="pl-3">
                                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-10">
                          <hr className="border-blue-800 border" />
                        </div>
                        <div>
                          <Typography variant="h6" placeholder={'.'}>
                            Upload
                          </Typography>
                          <div className="p-4">
                            Uploads must be an xlsx file with the data on the
                            first sheet.
                            <br />
                            The sheet name does not matter but the titles and
                            order of the rows must match the template.
                          </div>
                        </div>
                        <div className="w-96 p-4">
                          <Input
                            type="file"
                            label="UPC List"
                            crossOrigin={'false'}
                            className=""
                            onChange={onFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-12">
                      UPC list could not be found. This seems like an error.
                      Please try again and contact support if the problem
                      persists.
                    </div>
                  )}
                </div>
              )}
              {newDocReady && newUpcDoc && (
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row align-middle">
                      <div className="p-2">
                        {categoriesToggle ? (
                          <div className="text-black">Kroger </div>
                        ) : (
                          <div className="text-black">Circana </div>
                        )}
                      </div>
                      <div className="p-2">
                        <Switch crossOrigin={'.'} onClick={toggleCategories} />
                      </div>
                    </div>

                    <div className="p-2">
                      <Button placeholder={'.'} onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div className="rounded-t-md overflow-clip">
                    <table className="w-full min-w-fit table-auto text-left">
                      <thead>
                        <tr>
                          {headers().map((title) => {
                            return (
                              <th className="border-b border-black bg-blue-gray-50 p-2">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none text-black"
                                >
                                  {title}
                                </Typography>
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {newUpcDoc && (
                          <>
                            {sortUPCs(Object.keys(newUpcDoc)).map((upc) => (
                              <tr>
                                <td className={table_cell_classes}>{upc}</td>
                                <td className={table_cell_classes}>
                                  {newUpcDoc[upc].case_UPC}
                                </td>
                                <td className={table_cell_classes}>
                                  {infoChip(newUpcDoc[upc].in_kroger)}
                                </td>
                                <td className={table_cell_classes}>
                                  {infoChip(newUpcDoc[upc].in_circana)}
                                </td>
                                {filterValuesByToggle().map((key) => (
                                  <>
                                    {newUpcDoc[upc][key] ? (
                                      <td className={table_cell_classes}>
                                        {newUpcDoc[upc][key]}
                                      </td>
                                    ) : (
                                      <td className={table_cell_classes}></td>
                                    )}
                                  </>
                                ))}
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="p-10">
              <Loading />
            </div>
          )}
        </div>
      ) : (
        <div className="p-10">
          <Loading />
        </div>
      )}
    </div>
  )
}
