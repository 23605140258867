import {
  ChevronLeftIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import {
  Button,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import { account } from '../types/account'
import { AccountsContext, ClientsContext } from '../contexts'
import Loading from './Loading'
import { client } from '../types/client'
import AccountName from './AccountName'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { Link } from 'react-router-dom'

export default function EditBySelection({
  backArrow,
  selectedClients,
}) {
  const accounts = useContext(AccountsContext)
  const clients = useContext(ClientsContext)
  const [roleTitles, setRoleTitles] = useState<string[]>([])
  const [selectedTitle, setSelectedTitle] = useState<string>('')

  const [insertQuery, setInsertQuery] = useState<string>('')

  const [insertAcct, setInsertAcct] = useState<account | undefined>()

  const [submitting, setSubmitting] = useState<boolean | undefined>()
  const [successfulSubmission, setSuccessfulSubmission] = useState<
    boolean | undefined
  >()

  const [loadingQueryResults, setLoadingQueryResults] = useState<
    boolean | undefined
  >()

  function insertAcctSearch(accounts) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(insertQuery.toLowerCase())
    })
  }

  const [clientsAfterInsert, setClientsAfterInsert] = useState<
    client[] | undefined
  >()

  function getClientDoc(client_id) {
    var client_doc: client = {
      id: '',
      active: false,
      potential_client: false,
      aliases: [],
      brands: [],
      commission_file: '',
      contacts: {},
      roles: {},
      end_date: '',
      start_date: '',
      file_id: '',
      logo: '',
      name: '',
      tier: '',
      child: '',
    }

    clients.forEach((client) => {
      if (client) {
        if (client.id === client_id) {
          client_doc = JSON.parse(JSON.stringify(client))
        }
      }
    })
    return client_doc
  }

  function loadQueryResults() {
    console.log(insertAcct)
    console.log(selectedTitle)
    console.log(selectedClients)
    var clients_after_insert: client[] = []
    if (insertAcct && selectedTitle) {
      var original_clients = JSON.parse(JSON.stringify(selectedClients))
      original_clients.forEach((client_id) => {
        const client = getClientDoc(client_id)
        if (client) {
          var client_copy = JSON.parse(JSON.stringify(client))
          client_copy.roles[selectedTitle] = [insertAcct.id]
          clients_after_insert.push(JSON.parse(JSON.stringify(client_copy)))
        } else {
          console.log('Client Doc could not be located.')
        }
      })
      setClientsAfterInsert(clients_after_insert)
      console.log(clients_after_insert)
    }
    setLoadingQueryResults(false)
  }

  async function getRoles() {
    await getDoc(doc(db, 'settings', 'roles')).then((doc) => {
      if (doc.exists()) {
        setRoleTitles(doc.data().options)
      }
    })
  }

  async function handleSubmit() {
    const confirm_request = `are you sure you sure that you want to implement this change? It will affect ${clientsAfterInsert?.length} cleints.`
    const response = window.confirm(confirm_request)
    if (response === true && clientsAfterInsert) {
      for (let i = 0; i < clientsAfterInsert.length; i++) {
        if (clientsAfterInsert[i]) {
          const clientDoc = clientsAfterInsert[i]
          await setDoc(doc(db, 'clients', clientDoc.id), clientDoc)
        }
      }
      setSubmitting(false)
      setSuccessfulSubmission(true)
    } else {
      setSubmitting(false)
      setSuccessfulSubmission(false)
    }
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getRoles()
      setFirstLoad(false)
    }
  })

  return (
    <div className="w-full h-full">
      <div className="flex justify-between w-full">
        <IconButton
          placeholder={'.'}
          variant="text"
          className=""
          onClick={backArrow}
        >
          <ChevronLeftIcon className="w-10 h-10" />
        </IconButton>
        <div className=" text-xl font-black text-black px-4 py-2">
          Quick Edit
        </div>
        <div className="w-14"></div>
      </div>
      <hr />
      {submitting === undefined ? (
        <div>
          <div>
            <div className="flex flex-row p-4">
              <Typography
                placeholder={'.'}
                variant="h4"
                className="pr-4 text-black"
              >
                Edit By Selection
              </Typography>
              <Tooltip
                content={
                  <div>
                    The clients selected will have the account selected in the
                    'insert' field inserted into the 'role' picked from the
                    dropdown.
                  </div>
                }
              >
                <IconButton variant="text" placeholder={'.'}>
                  <InformationCircleIcon className="h-8 w-8" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="p-4">
              <div>
                {insertAcct ? (
                  <Link to="">
                    <Typography
                      placeholder={'.'}
                      variant="h6"
                      className="p-2"
                      color="blue"
                      onClick={() => setInsertAcct(undefined)}
                    >
                      Insert: {insertAcct?.name}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    placeholder={'.'}
                    variant="h6"
                    className="p-2 text-black"
                  >
                    Insert:
                  </Typography>
                )}
              </div>
              {insertAcct === undefined && (
                <div className="p-2">
                  <div className="w-96 py-2">
                    <Input
                      crossOrigin={false}
                      label="Search"
                      value={insertQuery}
                      onChange={(e) => {
                        setInsertQuery(e.target.value)
                      }}
                      icon={<MagnifyingGlassIcon />}
                    />
                  </div>
                  <div className="w-96 py-2">
                    <ul className="flex h-48 w-full flex-col overflow-auto rounded-md border-2">
                      {insertAcctSearch(accounts).map(
                        (account: account, index) => {
                          if (account.internal) {
                            return (
                              <li
                                className="px-2 py-1"
                                key={index + '_cleintButton'}
                              >
                                <Button
                                  placeholder="."
                                  variant="text"
                                  className="rounded-none p-1"
                                  onClick={() => {
                                    setInsertAcct(account)
                                  }}
                                >
                                  {account?.name}
                                </Button>
                              </li>
                            )
                          } else {
                            return (
                              <div key={index + '_clientButtonEmpty'}></div>
                            )
                          }
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="px-8">
              <Menu>
                <MenuHandler>
                  <Button placeholder={'.'} variant="outlined">
                    Role: {selectedTitle}
                  </Button>
                </MenuHandler>
                <MenuList placeholder={'.'}>
                  {roleTitles.map((title) => {
                    return (
                      <MenuItem placeholder={'.'} key={title}>
                        <div
                          onClick={() => {
                            return setSelectedTitle(title)
                          }}
                        >
                          {title}
                        </div>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            </div>
            <div className="p-8">
              {insertAcct && selectedTitle ? (
                <Button
                  placeholder={'.'}
                  color="blue"
                  onClick={() => {
                    setLoadingQueryResults(true)
                    loadQueryResults()
                  }}
                >
                  Preview Change
                </Button>
              ) : (
                <Button placeholder={'/'} disabled variant="outlined">
                  Preview Change
                </Button>
              )}
            </div>
          </div>
          {loadingQueryResults !== undefined && (
            <div>
              {loadingQueryResults === true ? (
                <div className="p-8">
                  <Loading />
                </div>
              ) : (
                <div>
                  {selectedClients && (
                    <div>
                      <Typography
                        placeholder={'.'}
                        variant="h4"
                        className="px-6 text-black"
                      >
                        Before
                      </Typography>
                      <div className="p-6">
                        <table className="min-h-full w-full table-auto text-left p-0">
                          <thead>
                            <tr>
                              <th className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none"
                                >
                                  Name
                                </Typography>
                              </th>
                              {roleTitles.map((column) => (
                                <th
                                  key={column}
                                  className=" border-b border-blue-gray-100 bg-blue-gray-50 px-2 py-1 w-28"
                                >
                                  <div className=" flex flex-row w-fit justify-between">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none"
                                    >
                                      {column}
                                    </Typography>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedClients &&
                              selectedClients.map((client_id, index) => {
                                const client = getClientDoc(client_id)
                                return (
                                  <tr key={index} className={' h-14'}>
                                    <td
                                      key={client.id + index}
                                      className={'py-0 px-4 border text-black'}
                                    >
                                      {client.name}
                                    </td>
                                    {roleTitles.map((title, index2) => {
                                      return (
                                        <td
                                          key={client?.id + index2}
                                          className={'py-0 px-4 border'}
                                        >
                                          {client.roles[title] &&
                                            client?.roles[title].map(
                                              (account_id) => (
                                                <AccountName
                                                  accounts={accounts}
                                                  account_id={account_id}
                                                />
                                              )
                                            )}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {clientsAfterInsert && (
                    <div>
                      <Typography
                        placeholder={'.'}
                        variant="h4"
                        className="px-6 text-black"
                      >
                        After
                      </Typography>
                      <div className="p-6">
                        <table className="min-h-full w-full table-auto text-left p-0">
                          <thead>
                            <tr>
                              <th className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none"
                                >
                                  Name
                                </Typography>
                              </th>
                              {roleTitles.map((column) => (
                                <th
                                  key={column}
                                  className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28"
                                >
                                  <div className=" flex flex-row w-fit justify-between">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none"
                                    >
                                      {column}
                                    </Typography>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {clientsAfterInsert.map((client, index) => (
                              <tr key={index} className={' h-14'}>
                                <td
                                  key={client.id + index}
                                  className={'py-0 px-4 border text-black'}
                                >
                                  {client.name}
                                </td>
                                {roleTitles.map((title, index2) => {
                                  return (
                                    <td
                                      key={client.id + index2}
                                      className={'py-0 px-4 border'}
                                    >
                                      {client.roles[title] &&
                                        client.roles[title].map(
                                          (account_id) => (
                                            <AccountName
                                              accounts={accounts}
                                              account_id={account_id}
                                            />
                                          )
                                        )}
                                    </td>
                                  )
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="p-8">
                    <Button
                      placeholder={'.'}
                      color="green"
                      onClick={async () => {
                        setSubmitting(true)
                        await handleSubmit()
                      }}
                    >
                      Implement Change
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {submitting === true ? (
            <div className="p-16">
              <Loading />
            </div>
          ) : (
            <div>
              {successfulSubmission ? (
                <div className="p-16">Successful Submission!</div>
              ) : (
                <div className="p-16">Unsuccessful Submission.</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
