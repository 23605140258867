import { client } from '../../types/client'

export default function ensureClientType(input_obj) {
  var client_template: client = {
    active: false,
    potential_client: false,
    aliases: [],
    brands: [],
    commission_file: '',
    contacts: {},
    roles: {},
    end_date: '',
    start_date: '',
    file_id: '',
    id: '',
    logo: '',
    name: '',
    tier: '',
    gln: '',
    duns: '',
    k_num: '',
  }

  Object.keys(input_obj).forEach((key) => {
    client_template[key] = input_obj[key]
  })
  return client_template
}
