import { useContext, useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'
import { db } from '../../../../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { additionalInfo } from '../../../../../types/additionalInfo'
import { PermissionsContext } from '../../../../../contexts'
import Comments from './Comments'
import MarketingFields from './MarketingFields'
import Logins from './Logins'
import Links from './Links'

export function AdditionalInfo({ client_id }) {
  function reloadAdditionalInfo() {
    setDataReady(false)
    setFirstLoad(true)
  }

  const [allInfo, setAllInfo] = useState<additionalInfo>({
    comments: '',
    documents: {},
    logins: [],
    broker_portal: false,
    marketing: {
      kpm: '',
      active_programs: '',
    },
  })
  const [firstLoad, setFirstLoad] = useState(true)
  const permissions = useContext(PermissionsContext)
  const [dataReady, setDataReady] = useState(false)

  async function fetchInfo(id) {
    await getDoc(doc(db, 'additional_client_info', String(id))).then(
      (docSnap) => {
        var doc = docSnap.data()
        var data: additionalInfo = {
          comments: '',
          documents: {},
          logins: [],
          links: {},
          broker_portal: false,
          marketing: {
            kpm: '',
            active_programs: '',
          },
        }
        if (doc !== undefined) {
          if (doc.comments !== undefined) {
            data.comments = doc.comments
          }
          if (doc.documents !== undefined) {
            data.documents = doc.documents
          }
          if (doc.logins !== undefined) {
            data.logins = doc.logins
          }
          if (doc.broker_portal !== undefined) {
            data.broker_portal = doc.broker_portal
          }
          if (doc.marketing !== undefined) {
            data.marketing = doc.marketing
          }
          if (doc.links !== undefined) {
            data.links = doc.links
          }
        }
        setAllInfo(data)
        setDataReady(true)
        console.log(data)
      }
    )
  }

  useEffect(() => {
    if (firstLoad) {
      fetchInfo(client_id)
      setFirstLoad(false)
    }
  }, [firstLoad, fetchInfo, client_id, permissions.view_payments])
  return (
    <div className="min-h-96">
      {dataReady ? (
        <div>
          <div className="flex flex-row flex-wrap justify-between">
            <Comments
              allInfo={allInfo}
              reloadParent={reloadAdditionalInfo}
              client_id={client_id}
            />
            <Links
              allInfo={allInfo}
              reloadParent={reloadAdditionalInfo}
              client_id={client_id}
            />
            {permissions.view_marketing && (
              <MarketingFields
                allInfo={allInfo}
                reloadParent={reloadAdditionalInfo}
                client_id={client_id}
              />
            )}
          </div>
          <hr></hr>
          <div className="w-full flex flex-col">
            <Logins
              allInfo={allInfo}
              reloadParent={reloadAdditionalInfo}
              client_id={client_id}
            />
          </div>
        </div>
      ) : (
        <div className="p-10">
          <Loading />
        </div>
      )}
    </div>
  )
}
