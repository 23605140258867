import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './index.css'
import SignIn from './pages/public/SignIn'
import { User } from 'firebase/auth'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
  ReloadDataContext,
} from './contexts'
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { db } from './firebase'
import { account } from './types/account'
import { client } from './types/client'
import { permission } from './types/permission'
import ResetPassword from './pages/public/ResetPassword'
import StandardLayout from './components/StandardLayout'
import LocalDataProvider from './routes/LocalDataProvider'
import hash from 'object-hash'
import { private_routes } from './routes/routes'

export default function App(props: { user: User | undefined }) {
  const [currentUserAccount, setUserAccount] = useState<account>({
    name: '',
    phone: '',
    email: '',
    title: '',
    internal: true,
    company: '',
    avatar: '',
    id: '',
  })
  const [permissions, setPermissions] = useState<permission>({
    title: 'Default',
    add_accounts: false,
    edit_accounts: false,
    edit_my_clients_accounts: false,
    edit_current_user_account: false,
    add_clients: false,
    edit_all_clients: false,
    edit_assigned_clients: false,
    view_tiers: false,
    edit_payments: false,
    view_payments: false,
    view_my_client_payments: false,
    edit_all_roles: false,
    edit_my_client_roles: false,
    edit_all_contacts: false,
    edit_my_client_contacts: false,
    view_settings: false,
    edit_presets: false,
    edit_marketing: false,
    view_marketing: false,
  })
  const [fetchedData, setFetchedData] = useState(false)

  async function getAccount(user: User) {
    const UID = user?.uid
    const accountsRef = collection(db, 'accounts')
    const q = query(
      accountsRef,
      where('email', '==', user.email),
      where('internal', '==', true)
    )
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      var response_accts: account[] = []
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        var account: account = {
          name: '',
          phone: '',
          email: '',
          title: '',
          internal: true,
          company: '',
          avatar: '',
          id: '',
        }
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            try {
              account[keys[i]] = data[keys[i]]
            } catch (err) {
              console.log(
                'There was an error copying a portion of your account information.',
                err
              )
            }
          }
          response_accts.push(account)
        }
      })
      if (response_accts.length === 1) {
        setUserAccount(response_accts[0])
        setDoc(doc(db, 'users', UID), { id: response_accts[0].id })
        if (
          response_accts[0].UID === undefined ||
          response_accts[0].UID === ''
        ) {
          setDoc(
            doc(db, 'accounts', response_accts[0].id),
            { UID: UID },
            { merge: true }
          )
        }
      } else {
        // error finding account.
      }
    })
  }
  async function getPermissions(user: User) {
    const permissions_doc = doc(db, 'permissions', user.uid)
    const unsub = onSnapshot(permissions_doc, (querySnapshot) => {
      if (querySnapshot.exists()) {
        var permission: permission = {
          title: 'Default',
          add_accounts: false,
          edit_accounts: false,
          edit_my_clients_accounts: false,
          edit_current_user_account: false,
          add_clients: false,
          edit_all_clients: false,
          edit_assigned_clients: false,
          view_tiers: false,
          edit_payments: false,
          view_payments: false,
          view_my_client_payments: false,
          edit_all_roles: false,
          edit_my_client_roles: false,
          edit_all_contacts: false,
          edit_my_client_contacts: false,
          view_settings: false,
          edit_presets: false,
          edit_marketing: false,
          view_marketing: false,
        }
        const data = querySnapshot.data()
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            permission[keys[i]] = data[keys[i]]
          }
        }
        setPermissions(permission)
      }
    })
  }

  // Setup Static Records of all Accounts and Clients
  const [clients, setClients] = useState<client[]>([])
  const [clients_hash, setClientsHash] = useState('')
  async function getClients() {
    const clients_collection = collection(db, 'clients')
    const querySnapshot = await getDocs(clients_collection)
    var clients_temp: client[] = []
    querySnapshot.forEach((doc) => {
      var client: client = {
        name: '',
        active: false,
        potential_client: false,
        id: '',
        file_id: '',
        commission_file: '',
        tier: '',
        logo: '',
        child: '',
        start_date: '',
        end_date: '',
        aliases: [],
        brands: [],
        contacts: {},
        roles: {},
      }
      const data = doc.data()
      if (data) {
        const keys = Object.keys(data)
        for (let i in keys) {
          client[keys[i]] = data[keys[i]]
        }
        clients_temp.push(client)
      }
    })
    setClientsHash(hash(clients_temp))
    setClients(clients_temp)
  }
  const [accounts, setAccounts] = useState<account[]>([])
  const [accounts_hash, setAccountsHash] = useState('')
  async function getAccounts() {
    const accounts_collection = collection(db, 'accounts')
    const querySnapshot = await getDocs(accounts_collection)
    var accounts_temp: account[] = []
    querySnapshot.forEach((doc) => {
      var account: account = {
        name: '',
        phone: '',
        email: '',
        title: '',
        internal: true,
        company: '',
        avatar: '',
        id: '',
      }
      const data = doc.data()
      if (data) {
        const keys = Object.keys(data)
        for (let i in keys) {
          account[keys[i]] = data[keys[i]]
        }
        accounts_temp.push(account)
      }
    })
    setAccountsHash(hash(accounts_temp))
    setAccounts(accounts_temp)
  }

  if (window.location.href.includes('osmg-internal.web.app')) {
    alert(
      'You are accessing Orbit through an old URL which has been decomissioned to improve security.\nYou are going to be redirected to the new site.'
    )
    window.location.href = 'https://osmg.app'
  }

  useEffect(() => {
    if (!fetchedData && props.user) {
      getAccount(props.user)
      getPermissions(props.user)
      getClients()
      getAccounts()
      setFetchedData(true)
      console.log('App was reloaded.')
    } else if (!fetchedData) {
      setFetchedData(true)
      // when no user is present, additional data is not needed.
      // App.tsx's parent reloads on authStateChanged
      // App.tsx will never render without props.user if user is authenticated.
    }
  }, [
    fetchedData,
    getAccount,
    props.user,
    getPermissions,
    getClients,
    getAccounts,
    setFetchedData,
  ])

  function updateClientsIfNeeded() {
    const snap_clients_hash = localStorage.getItem('clients_hash')
    if (clients_hash !== snap_clients_hash) {
      const snap_clients = localStorage.getItem('clients')
      if (snap_clients && snap_clients_hash) {
        setClients(JSON.parse(snap_clients))
        setClientsHash(snap_clients_hash)
        console.log('Working copy of Clients data was updated.')
      } else {
        console.log(
          'Most recent snapshot of Clients did not match working copy but there was an ERROR updating.'
        )
      }
    } else {
      console.log('Checked for updates to Clients, no new changes were found.')
    }
  }
  function updateAccountsIfNeeded() {
    const snap_accounts_hash = localStorage.getItem('accounts_hash')
    if (accounts_hash !== snap_accounts_hash) {
      const snap_accounts = localStorage.getItem('accounts')
      if (snap_accounts && snap_accounts_hash) {
        setAccounts(JSON.parse(snap_accounts))
        setAccountsHash(snap_accounts_hash)
        console.log('Workign copy of Accounts data was updated.')
      } else {
        console.log(
          'Most recent snapshot of Accounts did not match current copy but there was an ERROR updating.'
        )
      }
    } else {
      console.log('Checked for updates to Accounts, no new changes were found.')
    }
  }
  function updateDataIfNeeded() {
    if (props.user) {
      console.log('Checking accounts and clients are updated...')
      updateClientsIfNeeded()
      updateAccountsIfNeeded()
    } else {
      // this should never happen, but is a precaution to appease the typescript gods.
      console.log('Could update data, User was undefined.')
    }
  }

  return (
    <>
      {!props.user ? (
        <BrowserRouter>
          <Routes>
            <Route
              path={'/reset/password'}
              Component={() => <ResetPassword />}
            />
            <Route path="/*" Component={() => <SignIn />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <>
          <LocalDataProvider />
          {fetchedData ? (
            <BrowserRouter>
              <CurrentUserContext.Provider value={currentUserAccount}>
                <PermissionsContext.Provider value={permissions}>
                  <ClientsContext.Provider value={clients}>
                    <AccountsContext.Provider value={accounts}>
                      <ReloadDataContext.Provider value={updateDataIfNeeded}>
                        <StandardLayout>
                          <Routes>
                            {private_routes.map((route_obj)=>{
                              return (
                                <Route path={route_obj.path} Component={route_obj.component}/>
                              )
                            })}
                          </Routes>
                        </StandardLayout>
                      </ReloadDataContext.Provider>
                    </AccountsContext.Provider>
                  </ClientsContext.Provider>
                </PermissionsContext.Provider>
              </CurrentUserContext.Provider>
            </BrowserRouter>
          ) : (
            <>Collecting Data from firebase.</>
          )}
        </>
      )}
    </>
  )
}
