import { collection, onSnapshot } from "firebase/firestore";
import { account } from "../types/account";
import { client } from "../types/client";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import hash from "object-hash"

export default function LocalDataProvider() {
  // this function, collects all accounts and clients, then saves them to local storage. 
  // the idea here is to have a fully up to date copy for updating the working copy in App.tsx as needed.
  // reloading the whole site on any change to the db is ridiculous, but coping the local storage copy 
  // as needed is faster and more cost effective than repulling from firebase

  async function getClients() {
    const clients_collection = collection(db, 'clients')
    const unsubscripe = onSnapshot(clients_collection, (querySnapshot) => {
      var clients: client[] = []
      querySnapshot.forEach((doc) => {
        var client: client = {
          name: '',
          active: false,
          potential_client: false,
          id: '',
          file_id: '',
          commission_file: '',
          tier: '',
          logo: '',
          child: '',
          start_date: '',
          end_date: '',
          aliases: [],
          brands: [],
          contacts: {},
          roles: {},
        }
        const data = doc.data()
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            client[keys[i]] = data[keys[i]]
          }
          clients.push(client)
        }
      })
      localStorage.setItem("clients", JSON.stringify(clients));
      localStorage.setItem("clients_hash", hash(clients))
      console.log("Local Copy of Clients updated from Firebase.")
    })
  }
  async function getAccounts() {
    const accounts_collection = collection(db, 'accounts')
    const unsubscribe = onSnapshot(accounts_collection, (querySnapshot) => {
      var accounts: account[] = []
      querySnapshot.forEach((doc) => {
        var account: account = {
          name: '',
          phone: '',
          email: '',
          title: '',
          internal: true,
          company: '',
          avatar: '',
          id: '',
        }
        const data = doc.data()
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            account[keys[i]] = data[keys[i]]
          }
          accounts.push(account)
        }
      })
      localStorage.setItem("accounts", JSON.stringify(accounts));
      localStorage.setItem("accounts_hash", hash(accounts));
      console.log("Local Copy of Accounts updated from firebase.")
    })
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getClients()
      getAccounts()
      setFirstLoad(false)
    }
  }, [firstLoad, getClients, getAccounts, setFirstLoad])

  return <></>
}